import * as Sentry from '@sentry/remix'
import { Event } from '@sentry/types'

const logger = {
  /**
   * Debug: Use this level to log detailed information about UI components or
   * user interactions, typically for debugging purposes.
   * ```
   * logger.debug(`Dropdown component state: ${JSON.stringify(dropdownState)}`)
   * ```
   */
  debug: (message: string, captureContext?: Event) => {
    console.log('[DEBUG]', message)
    Sentry.captureEvent({ message, ...captureContext, level: 'debug' })
  },

  /**
   * Info: Use this level to log general information about UI events, which
   * might be useful for monitoring or understanding user behavior.
   * ```
   * logger.info(`User ${userId} successfully submitted the form`)
   * ```
   */
  info: (message: string, captureContext?: Event) => {
    console.log('[INFO]', message)
    Sentry.captureEvent({ message, ...captureContext, level: 'info' })
  },

  /**
   * Log: Use this level for more generic, low-priority messages that do not
   * require immediate attention in the UI.
   * ```
   * logger.log(`User preference for ${preferenceKey} updated to ${newValue}`)
   * ```
   */
  log: (message: string, captureContext?: Event) => {
    console.log('[LOG]', message)
    Sentry.captureEvent({ message, ...captureContext, level: 'log' })
  },

  /**
   * Warning: Use this level to log issues or unexpected behavior in the UI that
   * might lead to a suboptimal user experience but are not considered errors.
   * ```
   * logger.warning(`UI component ${componentName} failed to load, displaying fallback`)
   * ```
   */
  warning: (message: string, captureContext?: Event) => {
    console.warn('[WARNING]', message)
    Sentry.captureEvent({ message, ...captureContext, level: 'warning' })
  },

  /**
   * Error: Use this level to log errors and exceptions that occur during user
   * interactions or component rendering, which could disrupt the user
   * experience.
   * ```
   * logger.error(`API request to ${apiUrl} failed with status code ${statusCode}`)
   * ```
   */
  error: (message: string, captureContext?: Event) => {
    console.error('[ERROR]', message)
    Sentry.captureEvent({ message, ...captureContext, level: 'error' })
  },

  /**
   * Fatal: Use this level to log critical issues that cause the application to
   * crash or become unresponsive, leading to a complete failure of the user
   * interface.
   * ```
   * logger.fatal(`Unrecoverable JavaScript error: ${errorDetails}`)
   * ```
   */
  fatal: (message: string, captureContext?: Event) => {
    console.error('[FATAL]', message)
    Sentry.captureEvent({ message, ...captureContext, level: 'fatal' })
  },

  /**
   * Used for logging errors within catch blocks.
   * ```
   * try {} catch (error) {
   *   logger.exception(error)
   * }
   * ```
   */
  exception: (error: unknown) => {
    console.error(error)
    Sentry.captureException(error)
  },
} as const

export default logger
